const rangesliderTexts = [
    {
        cat: "passat",
        esp: "pasado",
        eng: "past"
    },
    {
        cat: "present",
        esp: "presente",
        eng: "present"
    },
    {
        cat: "futur",
        esp: "futuro",
        eng: "future"
    },
]

export default rangesliderTexts;